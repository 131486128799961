import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import Whatsapp from "../components/integrations/Whatsapp"
import AmberScore from "../components/reusable/AmberScore"

const whatsapp = () => (
  <Layout extra_class={'mg-top-80'}>
    <SEOComponent title="Amber by inFeedo | Integrations" />
    <Whatsapp/>
    <AmberScore/>
  </Layout>
)

export default whatsapp;
